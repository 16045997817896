export const layoutSize = { x: 7, y: 5, z: 5 };

export default [
  0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 1, 0,
  0, 1, 1, 0, 0, 1, 1,
  0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0,

  0, 0, 0, 0, 1, 0, 0,
  0, 0, 1, 1, 1, 1, 0,
  1, 1, 1, 1, 1, 1, 1,
  0, 1, 0, 0, 1, 1, 1,
  0, 0, 0, 0, 0, 1, 0,

  0, 0, 0, 0, 1, 0, 0,
  0, 1, 1, 1, 1, 1, 0,
  1, 1, 1, 1, 1, 1, 1,
  0, 1, 1, 1, 1, 1, 1,
  0, 0, 0, 0, 0, 1, 0,

  0, 0, 0, 0, 0, 0, 0,
  0, 1, 1, 1, 1, 1, 0,
  1, 1, 1, 1, 1, 1, 0,
  0, 1, 1, 1, 1, 1, 0,
  0, 0, 0, 1, 0, 0, 0,

  0, 0, 0, 0, 1, 0, 0,
  0, 0, 0, 1, 1, 0, 0,
  0, 1, 1, 1, 1, 1, 0,
  0, 1, 1, 1, 1, 0, 0,
  0, 0, 1, 1, 0, 0, 0,
];