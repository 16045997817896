export const background = "#0E111F";
export const border = "#1F222F";
export const label = "#9F9FA4";
export const navigation = "#198CB7";
export const chartAnimal = "#44C1EF";
export const chartHuman = "#45988E";
export const valueAnimal = "#C66B45"; // "#E8A38E";
export const valueHuman = "#D93D04"; // "#ED6E3D";
export const title = "#FFFFFF";


// from `non_brute_force_GUI_guideline_2st_draft.pdf`
// export const array = [
//   "#00010E", // background
//   "#031E25", // border
//   "#2E4A5B",
//   "#7FAEC0",
//   "#A1CFDD",
//   "#D93D04",
//   "#E8A38E",
//   "#F2DFCE",
// ];


// from `GUI_edited_ver (1).pdf`
// export const array = [
//   "#00010D", // 0  background / active output text
//   "#141C29", // 1  border
//   "#1A2532", // 2  navigation text
//   "#344958", // 3  active navigation text / active border / output text
//   "#050F18", // 4  secondary background
//   "#EFE0D0", // 5  title / small text
//   "#A8CDDB", // 6  big text / heartrate
//   "#344958", // 7  layout tiles
//   "#E1652B", // 8  orange heart / layout floors / output background / loss / acc lines
//   "#1A2532", // 9  button background
//   "#293948", // 10 button border
// ];

// from `non_brute_force_GUI_guideline_main_page_(1-4).pdf`
// export const array = [
//   "#000208", // 0  background / active output text
//   "#233038", // 1  border
//   "#121B23", // 2  navigation text
//   "#304048", // 3  active navigation text / active border / output text
//   "#01060E", // 4  secondary background
//   "#E4D4C1", // 5  title / small text
//   "#A1C0C6", // 6  big text / heartrate
//   "#304048", // 7  layout tiles
//   "#C76C46", // 8  orange heart / layout floors / output background / loss / acc lines
//   "#121B23", // 9  button background
//   "#243139", // 10 button border
// ];
export const array = [
  "#00010E", // 0  background / active output text
  "#2E4A5B", // 1  border
  "#2E4A5B", // 2  navigation text
  "#2E4A5B", // 3  active navigation text / active border / output text
  "#050F18", // 4  secondary background 031E25 * .5 = 050F18
  "#F2DFCE", // 5  title / small text
  "#A0CEDC", // 6  big text / heartrate
  "#2E4A5B", // 7  layout tiles
  "#D93D04", // 8  orange heart / layout floors / output background / loss / acc lines
  "#1A2532", // 9  button background 2E4A5B * .5 = 1A2532
  "#2E4A5B", // 10 button border
];
