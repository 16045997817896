export default [
  // 20200526
  {
    title: "2020-05-26 1",
    data: [ 3, 4, 6, 7, 9, 11, 12, 14, 15, 24, 27, 29, 31, 34, 36, 38, 39, 44, 46, 48, 49, 51, 53, 54, 57, 59, 61, 62, 63, 64, 70, 72 ],
    george: 89,
    maja: 104,
  },
  // {
  //   title: "2020-05-26 2",
  //   data: [ 3, 4, 6, 7, 9, 11, 12, 13, 15, 24, 27, 29, 32, 35, 36, 38, 39, 44, 45, 48, 49, 52, 53, 54, 57, 59, 62, 63, 65, 70, 72 ],
  //   boogie: 98,
  //   maja: 87,
  // },
  // 20200527
  {
    title: "2020-05-27 1",
    data: [ 1, 3, 4, 5, 7, 9,  11, 12, 13, 15, 19, 24, 28, 29, 32, 35, 36, 40, 41, 46, 48, 49, 51, 54, 57, 59, 61, 62, 68, 70, 72 ],
    maja: 93,
    george: 80,
    // george_boogie: 85, // ?
    // boogie: 90,
  },
  {
    title: "2020-05-27 2",
    data: [ 1, 3, 4, 5, 8, 10, 11, 12, 13, 15, 19, 24, 28, 29, 35, 38, 40, 41, 43, 46, 48, 50, 51, 55, 56, 59, 61, 62, 68, 70, 72 ],
    maja: 90,
    george: 82,
    // george_boogie: 90, // ?
    // boogie: 98,
  },
  // 20200601
  // {
  //   title: "2020-06-01 1",
  //   data: [ 3, 4, 5, 7, 10, 13, 14, 15, 17, 19, 24, 28, 30, 32, 35, 38, 40, 41, 43, 46, 48, 50, 52, 54, 57, 59, 60, 62, 65, 68, 70, 72 ],
  //   maja: 90,
  //   boogie: 127,
  // },
  // {
  //   title: "2020-06-01 2",
  //   data: [ 4, 5, 10, 13, 14, 15, 16, 19, 24, 28, 30, 32, 34, 37, 38, 40, 41, 43, 47, 48, 50, 51, 54, 57, 59, 60, 61, 62, 64, 68, 70, 72 ],
  //   maja: 96,
  //   boogie: 93,
  // },
  // 20200602
  {
    title: "2020-06-02 1",
    data: [ 3, 4, 5, 7, 9,  12, 13, 15, 17, 19, 24, 26, 30, 31, 34, 36, 38, 40, 43, 46, 48, 50, 51, 55, 57, 59, 60, 61, 66, 68, 70, 72 ],
    maja: 93,
    george: 92, // georgy?
  },
  {
    title: "2020-06-02 2",
    data: [ 3, 4, 5, 7, 9,  11, 12, 16, 17, 19, 26, 27, 29, 31, 34, 36, 40, 43, 48, 49, 52, 55, 57, 59, 60, 61, 62, 68, 70, 72 ],
    maja: 97,
    george: 142, // georgy?
  },
  {
    title: "2020-06-02 3",
    data: [ 3, 4, 5, 7, 9,  11, 12, 13, 15, 17, 19, 24, 26, 29, 30, 34, 36, 38, 40, 43, 46, 48, 49, 50, 54, 57, 59, 61, 62, 68, 70, 71, 72 ],
    maja: 92,
    mala: 81,
  },
  {
    title: "2020-06-02 4",
    data: [ 3, 4, 5, 7, 10, 11, 12, 15, 17, 19, 24, 26, 29, 30, 34, 36, 38, 41, 43, 46, 48, 49, 52, 54, 57, 59, 60, 61, 68, 70, 71, 72 ],
    maja: 86,
    mala: 88,
  },
  // 20200603
  {
    title: "2020-06-03 1",
    data: [ 3, 4, 5, 7, 9,  11, 12, 15, 16, 20, 26, 28, 29, 30, 32, 36, 39, 41, 43, 46, 48, 49, 52, 54, 56, 59, 60, 61, 63, 69, 70, 71 ],
    maja: 95,
    mala: 154,
  },
  {
    title: "2020-06-03 2",
    data: [ 3, 4, 5, 8, 9,  11, 13, 14, 17, 20, 25, 28, 29, 31, 33, 36, 37, 41, 43, 46, 48, 49, 52, 56, 57, 59, 62, 63, 64, 65, 70, 71 ],
    maja: 99,
    mala: 87,
  },
  {
    title: "2020-06-03 3",
    data: [ 3, 4, 5, 9, 10, 11, 13, 14, 17, 20, 25, 26, 30, 32, 33, 36, 38, 41, 43, 46, 48, 49, 52, 55, 57, 59, 60, 61, 63, 70, 71, 72 ],
    maja: 98,
    george: 96,
  },
  {
    title: "2020-06-03 4",
    data: [ 3, 4, 5, 9, 10, 11, 13, 14, 17, 20, 25, 26, 30, 32, 33, 36, 40, 41, 43, 46, 47, 48, 49, 52, 55, 57, 59, 61, 62, 63, 70, 71, 72 ],
    maja: 93,
    george: 119,
  },
  // {
  //   title: "2020-06-03 5",
  //   data: [ 3, 4, 5, 8, 10, 11, 13, 15, 17, 20, 25, 26, 30, 32, 34, 36, 38, 41, 43, 44, 46, 47, 48, 50, 52, 55, 57, 59, 60, 61, 63, 66, 71, 72 ],
  //   maja: 101,
  //   boogie: 75,
  // },
  {
    title: "2020-06-03 6",
    data: [ 3, 4, 5, 7, 10, 11, 12, 15, 17, 20, 25, 26, 29, 31, 34, 36, 38, 41, 45, 46, 48, 49, 51, 55, 57, 59, 60, 61, 66, 68, 70, 72 ],
    maja: 92,
    ada: 69,
  },
  // 20200604
  {
    title: "2020-06-04 1",
    data: [ 3, 4, 5, 7, 9, 11, 13, 15, 17, 20, 24, 25, 27, 29, 30, 32, 33, 39, 43, 44, 48, 49, 50, 51, 56, 59, 60, 61, 62, 68, 70, 72 ],
    maja: 92,
    ada: 96,
  },
  {
    title: "2020-06-04 2",
    data: [ 3, 4, 5, 8, 9, 11, 13, 15, 17, 19, 24, 25, 29, 32, 34, 38, 40, 41, 43, 46, 47, 48, 49, 52, 53, 57, 60, 61, 64, 68, 71, 73 ],
    maja: 87,
    george: 93,
  },
  // {
  //   title: "2020-06-04 3",
  //   data: [ 3, 4, 5, 7, 9, 11, 13, 15, 17, 19, 24, 26, 29, 32, 34, 38, 40, 41, 44, 46, 48, 50, 52, 55, 57, 59, 62, 65, 68, 71, 72, 73 ],
  //   maja: 96,
  //   boogie: 184,
  // },
  {
    title: "2020-06-04 4",
    data: [ 3, 4, 5, 7, 8, 11, 13, 15, 17, 20, 24, 27, 29, 30, 34, 38, 40, 41, 45, 46, 48, 51, 52, 54, 57, 59, 61, 63, 64, 70, 71, 73 ],
    maja: 97,
    ada: 98,
  },
  // 20200605
  // {
  //   title: "2020-06-05 1",
  //   data: [ 3, 4, 5, 7, 10, 11, 12, 14, 15, 20, 24, 27, 29, 31, 34, 38, 40, 41, 43, 44, 48, 51, 52, 54, 57, 62, 63, 70, 71, 72, 73 ],
  //   maja: 89,
  //   boogie: 108,
  // },
  // {
  //   title: "2020-06-05 2",
  //   data: [ 3, 4, 5, 7, 9,  11, 12, 14, 15, 16, 24, 27, 29, 31, 34, 38, 40, 41, 43, 45, 48, 49, 51, 54, 56, 60, 63, 69, 70, 71, 73 ],
  //   maja: 97,
  //   boogie: 85,
  // },
  {
    title: "2020-06-05 3",
    data: [ 3, 4, 5, 7, 10, 11, 12, 14, 15, 19, 25, 27, 29, 31, 34, 37, 40, 41, 43, 48, 49, 52, 55, 57, 59, 62, 65, 68, 69, 71, 73 ],
    maja: 93,
    george: 124,
  },
  {
    title: "2020-06-05 4",
    data: [ 3, 4, 5, 7, 10, 11, 14, 16, 19, 26, 27, 29, 31, 34, 36, 40, 41, 46, 48, 51, 52, 55, 57, 59, 61, 62, 64, 65, 68, 71 ],
    maja: 86,
    george: 150,
  },
  {
    title: "2020-06-05 5",
    data: [ 3, 4, 5, 7, 10, 11, 12, 13, 15, 20, 26, 27, 31, 32, 34, 36, 39, 41, 44, 46, 51, 52, 54, 56, 59, 61, 62, 63, 65, 69, 71 ],
    maja: 95,
    ada: 90,
  },
  // 20200608
  // {
  //   title: "2020-06-08 1",
  //   data: [ 3, 4, 5 ],
  //   maja: 88,
  //   george: 83,
  // },
  {
    title: "2020-06-08 2",
    data: [ 3, 4, 5, 7, 10, 11, 12, 16, 17, 20, 24, 27, 31, 32, 34, 36, 38, 41, 43, 48, 49, 52, 55, 57, 59, 61, 62, 63, 64, 66, 69 ],
    maja: 90,
    george: 117,
  },
  {
    title: "2020-06-08 3",
    data: [ 3, 4, 5, 7, 10, 11, 12, 16, 17, 20, 24, 26, 31, 32, 34, 36, 38, 41, 43, 48, 49, 51, 55, 57, 59, 61, 62, 63, 64, 66, 69, 70 ],
    maja: 97,
    mala: 98,
  },
  {
    title: "2020-06-08 4",
    data: [ 3, 4, 5, 9, 10, 11, 12, 16, 17, 20, 24, 25, 31, 33, 34, 36, 38, 41, 46, 48, 49, 52, 55, 57, 59, 60, 63, 64, 65, 66, 70 ],
    maja: 105,
    mala: 133,
  },
  // {
  //   title: "2020-06-08 5",
  //   data: [ 3, 4, 5, 9, 10, 11, 13, 16, 17, 20, 25, 27, 30, 33, 34, 37, 38, 41, 45, 48, 50, 51, 55, 57, 59, 60, 63, 65, 66, 70, 72 ],
  //   maja: 107,
  //   boogie: 88,
  // },
  // {
  //   title: "2020-06-08 6",
  //   data: [ 3, 4, 5, 8, 10, 11, 13, 14, 16, 21, 25, 27, 30, 33, 34, 36, 37, 38, 43, 48, 51, 52, 55, 56, 59, 62, 63, 65, 70, 71, 72 ],
  //   maja: 105,
  //   boogie: 129,
  // },
  // 20200609
  // {
  //   title: "2020-06-09 1",
  //   data: [ 3, 4, 5, 8, 10, 11, 12, 14, 15, 16, 19, 21, 25, 28, 30, 31, 35, 37, 39, 40, 43, 48, 51, 52, 54, 56, 59, 60, 63, 65, 69, 70, 71 ],
  //   maja: 82,
  //   mala: 0,
  // },
  {
    title: "2020-06-09 2",
    data: [ 3, 4, 5, 8, 9,  11, 12, 14, 16, 19, 24, 28, 29, 30, 35, 39, 40, 41, 45, 48, 50, 52, 54, 56, 59, 60, 63, 65, 69, 70, 71 ],
    maja: 89,
    george: 153,
  },
  // {
  //   title: "2020-06-09 3",
  //   data: [ 3, 4, 5, 7, 10, 11, 13, 14, 16, 19, 25, 26, 30, 33, 35, 37, 40, 41, 45, 48, 50, 51, 54, 56, 59, 60, 63, 65, 69, 70, 71 ],
  //   maja: 109,
  //   boogie: 94,
  // },
  // 20200611
  {
    title: "2020-06-11 1",
    data: [ 1, 3, 5, 7, 10, 11, 13, 15, 16, 20, 24, 25, 30, 32, 35, 36, 37, 39, 44, 48, 50, 51, 54, 56, 59, 60, 62, 63, 65, 70, 72 ],
    maja: 140,
    george: 119, // 114 / 124 AW
  },
  {
    title: "2020-06-11 2",
    data: [ 1, 3, 5, 7, 9,  10, 11, 13, 16, 20, 24, 27, 30, 32, 35, 36, 37, 39, 46, 48, 49, 52, 54, 56, 59, 61, 62, 63, 69, 70, 71 ],
    maja: 97,
    mala: 116, // 107 / 125 AW
  },
  // {
  //   title: "2020-06-11 3",
  //   data: [ 1, 3, 5, 7, 10, 11, 12, 13, 16, 20, 24, 27, 30, 32, 35, 36, 37, 39, 45, 48, 50, 52, 54, 56, 59, 61, 63, 64, 66, 70, 71 ],
  //   maja: 87,
  //   boogie: 113, // 105 / 122 AW
  // },
  // {
  //   title: "2020-06-11 4",
  //   data: [ 1, 3, 5, 7, 10, 12, 14, 15, 16, 20, 24, 27, 30, 33, 35, 36, 38, 39, 45, 48, 50, 51, 54, 55, 59, 61, 63, 64, 66, 70, 71 ],
  //   maja: 117,
  //   boogie: 87,
  // },
  // 20200612
  {
    title: "2020-06-12 1",
    data: [ 1, 3, 4, 7, 10, 12, 14, 15, 16, 20, 24, 25, 30, 34, 35, 36, 37, 39, 43, 49, 50, 51, 52, 54, 57, 59, 62, 63, 69, 70, 71, 73 ],
    maja: 106,
    george: 106, // 113/99aw
  },
  {
    title: "2020-06-12 2",
    data: [ 1, 3, 4, 7, 10, 11, 12, 15, 16, 20, 26, 27, 30, 34, 35, 37, 38, 39, 43, 44, 50, 51, 52, 54, 57, 59, 61, 62, 63, 71, 73 ],
    maja: 125,
    george: 125,
  },
  // {
  //   title: "2020-06-12 3",
  //   data: [ 1, 3, 5, 7, 9,  11, 12, 15, 16, 21, 24, 26, 30, 34, 35, 37, 39, 40, 43, 48, 49, 51, 56, 57, 59, 61, 62, 68, 69, 71, 73 ],
  //   maja: 124,
  //   boogie: 154,
  // },
  // {
  //   title: "2020-06-12 4",
  //   data: [ 1, 3, 4, 7, 10, 11, 12, 15, 16, 21, 26, 27, 30, 34, 35, 37, 39, 40, 44, 48, 49, 51, 56, 57, 59, 61, 66, 68, 71, 72, 73 ],
  //   maja: 88,
  //   boogie: 97,
  // },
  // 20200615
  {
    title: "2020-06-15 1",
    data: [ 1, 3, 5, 8, 9,  11, 13, 14, 16, 21, 26, 27, 30, 34, 35, 38, 39, 40, 43, 44, 48, 49, 51, 55, 57, 59, 61, 66, 68, 69, 70, 71 ],
    maja: 80,
    george: 176, // 174/178aw
  },
  {
    title: "2020-06-15 2",
    data: [ 1, 3, 4, 8, 10, 11, 12, 15, 16, 20, 26, 27, 30, 34, 35, 38, 39, 40, 43, 44, 48, 49, 52, 55, 56, 59, 61, 64, 66, 68, 70, 71 ],
    maja: 84,
    george: 140, // 147/133aw
  },
  // {
  //   title: "2020-06-15 3",
  //   data: [ 1, 3, 5, 9, 10, 11, 12, 14, 16, 21, 26, 28, 30, 33, 35, 38, 39, 40, 43, 44, 48, 50, 51, 55, 57, 59, 61, 66, 68, 71, 72, 73 ],
  //   maja: 87,
  //   boogie: 139,
  // },
  // {
  //   title: "2020-06-15 4",
  //   data: [ 1, 3, 5, 7, 9, 11, 12, 13, 14, 21, 26, 28, 30, 34, 35, 38, 39, 40, 43, 45, 48, 50, 51, 55, 57, 59, 61, 63, 66, 71, 72, 73 ],
  //   maja: 70,
  //   boogie: 126,
  // },
  // {
  //   title: "2020-06-15 5",
  //   data: [],
  // },
  // {
  //   title: "2020-06-15 6",
  //   data: [],
  // },
  // 20200616
  // {
  //   title: "2020-06-16 1",
  //   data: [ 1, 3, 4, 7, 9, 11, 12, 13, 16, 20, 26, 28, 32, 34, 35, 36, 39, 41, 43, 46, 49, 50, 52, 56, 57, 59, 60, 62, 65, 68, 70, 72 ],
  //   maja: 102,
  //   boogie: 124, // 124 / AW
  // },
  // {
  //   title: "2020-06-16 2",
  //   data: [ 1, 3, 4, 7, 9, 11, 12, 13, 16, 20, 24, 26, 33, 34, 35, 37, 39, 41, 43, 50, 51, 52, 56, 57, 59, 60, 62, 63, 65, 70, 72 ],
  //   maja: 85,
  //   boogie: 123,
  // },
  // {
  //   title: "2020-06-16 3",
  //   data: [],
  // },
  // 20200618
  // {
  //   title: "2020-06-18 1",
  //   data: [ 1, 3, 4, 9, 10, 11, 12, 13, 16, 20, 24, 26, 33, 34, 35, 36, 39, 41, 43, 46, 50, 51, 52, 56, 57, 59, 60, 62, 63, 65, 70, 72 ],
  //   maja: 89,
  //   boogie: 155,
  // },
  // {
  //   title: "2020-06-18 2",
  //   data: [ 1, 3, 4, 9, 10, 11, 12, 13, 16, 20, 24, 26, 30, 34, 35, 36, 39, 41, 43, 46, 50, 51, 52, 56, 57, 59, 60, 62, 65, 68, 70, 72 ],
  //   maja: 115,
  //   boogie: 130,
  // },
  {
    title: "2020-06-18 3",
    data: [ 1, 3, 4, 9, 10, 11, 12, 13, 16, 20, 25, 26, 32, 34, 35, 36, 40, 41, 43, 45, 49, 51, 52, 56, 57, 59, 60, 62, 64, 65, 68, 70 ],
    maja: 116,
    george: 157,
  },
  {
    title: "2020-06-18 4",
    data: [ 1, 3, 4, 9, 10, 11, 12, 13, 17, 21, 25, 26, 30, 34, 35, 36, 40, 41, 43, 46, 49, 51, 52, 56, 57, 59, 60, 61, 62, 64, 65, 68 ],
    maja: 107,
    ada: 97,
  },
  {
    title: "2020-06-18 5",
    data: [ 3, 5, 9, 10, 11, 13, 16, 17, 21, 26, 27, 32, 34, 35, 36, 40, 41, 43, 44, 50, 51, 52, 56, 57, 59, 60, 61, 62, 64, 65, 68 ],
    maja: 108,
    ada: 120,
  },
  // {
  //   title: "2020-06-18 6",
  //   data: [],
  // },
  // 20200619
  // {
  //   title: "2020-06-19 1",
  //   data: [ 1, 3, 4, 7, 8, 11, 13, 15, 17, 21, 24, 27, 32, 34, 35, 36, 38, 41, 43, 44, 49, 50, 52, 55, 57, 59, 60, 61, 62, 64, 65, 68 ],
  //   maja: 73,
  //   boogie: 66,
  // },
  // {
  //   title: "2020-06-19 2",
  //   data: [ 1, 3, 4, 7, 8, 11, 13, 15, 17, 21, 27, 28, 31, 32, 34, 36, 38, 41, 43, 44, 49, 50, 52, 54, 55, 59, 60, 61, 64, 65, 68, 71 ],
  //   maja: 109,
  //   boogie: 91,
  // },
  {
    title: "2020-06-19 3",
    data: [ 1, 3, 4, 7, 8, 11, 13, 14, 16, 20, 21, 27, 28, 29, 30, 34, 36, 40, 41, 43, 44, 49, 50, 51, 56, 57, 59, 60, 61, 64, 65, 68, 71 ], // 20?
    maja: 76,
    george: 144,
  },
  {
    title: "2020-06-19 4",
    data: [ 1, 3, 4, 7, 8, 11, 13, 14, 16, 20, 21, 27, 28, 29, 34, 35, 36, 37, 38, 43, 44, 49, 51, 52, 55, 57, 59, 60, 61, 62, 64, 68, 70 ],
    maja: 118,
    ada: 97,
  },
  // 20200622
  {
    title: "2020-06-22 1",
    data: [ 1, 3, 4, 7, 8, 11, 12, 13, 16, 20, 27, 28, 31, 34, 35, 37, 39, 40, 44, 45, 49, 51, 52, 55, 57, 59, 60, 62, 64, 68, 70, 73 ],
    maja: 89,
    ada: 72,
  },
  {
    title: "2020-06-22 2",
    data: [ 1, 3, 4, 7, 8, 11, 13, 14, 16, 20, 27, 28, 29, 34, 35, 37, 39, 40, 43, 45, 49, 51, 52, 55, 57, 59, 60, 62, 64, 68, 70, 73 ],
    maja: 84,
    ada: 92,
  },
  {
    title: "2020-06-22 3",
    data: [ 1, 3, 4, 8, 9, 11, 13, 14, 16, 20, 26, 28, 30, 34, 35, 37, 39, 40, 43, 46, 49, 51, 52, 55, 57, 59, 60, 62, 64, 68, 70, 73 ],
    maja: 86,
    george: 90,
  },
  {
    title: "2020-06-22 4",
    data: [ 1, 2, 4, 8, 9, 11, 12, 14, 17, 20, 25, 28, 30, 33, 35, 37, 39, 40, 45, 46, 48, 51, 52, 55, 56, 59, 60, 64, 68, 70, 71, 73 ],
    maja: 86,
    george: 85,
  },
  // 20200623
  {
    title: "2020-06-23 1",
    data: [ 1, 2, 4, 8, 10, 11, 12, 16, 17, 21, 26, 28, 29, 32, 35, 36, 39, 40, 44, 46, 49, 50, 52, 54, 56, 59, 60, 65, 66, 68, 72, 73 ],
    maja: 81,
    george: 167,
  },
  {
    title: "2020-06-23 2",
    data: [ 1, 2, 4, 7, 10, 11, 12, 16, 17, 21, 26, 28, 29, 33, 35, 36, 39, 40, 44, 46, 48, 50, 52, 54, 56, 59, 60, 65, 66, 68, 69, 73 ],
    maja: 90,
    george: 88,
  },
  {
    title: "2020-06-23 3",
    data: [ 1, 2, 5, 7, 10, 11, 12, 16, 17, 21, 26, 28, 29, 33, 35, 36, 39, 40, 44, 46, 48, 50, 52, 55, 56, 59, 60, 61, 62, 68, 69, 73 ],
    maja: 85,
    ada: 123,
  },
  {
    title: "2020-06-23 4",
    data: [ 1, 2, 4, 7, 10, 11, 12, 16, 17, 21, 24, 28, 29, 31, 35, 36, 39, 40, 44, 46, 48, 50, 52, 55, 56, 59, 60, 61, 66, 68, 72, 73 ],
    maja: 85,
    ada: 90,
  },
  // {
  //   title: "2020-06-23 5",
  //   data: [],
  // },
  // 20200626
  // {
  //   title: "2020-06-26 1",
  //   data: [ 1, 2, 4, 9, 10, 11, 12, 13, 15, 20, 25, 26, 29, 33, 35, 36, 39, 40, 43, 46, 49, 50, 51, 52, 54, 57, 59, 60, 63, 64, 66, 70, 73 ],
  //   maja: 88,
  //   boogie: 201,
  // },
  // {
  //   title: "2020-06-26 2",
  //   data: [ 1, 2, 5, 9, 10, 11, 12, 13, 17, 21, 24, 25, 29, 33, 35, 36, 37, 40, 43, 46, 49, 51, 54, 57, 59, 60, 63, 64, 66, 70, 73 ],
  //   maja: 89,
  //   boogie: 146,
  // },
  {
    title: "2020-06-26 3",
    data: [ 1, 2, 5, 9, 10, 11, 12, 13, 17, 21, 24, 25, 29, 32, 35, 36, 39, 40, 45, 46, 49, 51, 52, 54, 56, 59, 60, 66, 68, 69, 70, 73 ],
    maja: 83,
    ada: 143,
  },
  {
    title: "2020-06-26 4",
    data: [ 1, 2, 4, 8, 10, 11, 14, 15, 17, 19, 25, 26, 30, 32, 34, 36, 37, 38, 45, 46, 50, 51, 52, 56, 57, 59, 60, 61, 62, 68, 69, 70 ],
    maja: 96,
    ada: 92,
  },
  // 20200629
  // {
  //   title: "2020-06-29 1",
  //   data: [ 1, 2, 4, 7, 9,  10, 11, 12, 17, 21, 24, 27, 29, 30, 34, 38, 39, 40, 43, 50, 51, 52, 56, 57, 59, 60, 61, 62, 68, 69, 72 ],
  //   maja: 85, // 84/85aw
  //   boogie: 186,
  // },
  // {
  //   title: "2020-06-29 2",
  //   data: [ 1, 2, 4, 7, 10, 11, 12, 13, 17, 21, 24, 27, 30, 32, 34, 36, 39, 40, 43, 50, 51, 52, 56, 57, 59, 60, 61, 62, 68, 70, 72 ],
  //   maja: 81, // 81/81aw
  //   boogie: 182,
  // },
  {
    title: "2020-06-29 3",
    data: [ 1, 2, 4, 8, 10, 11, 12, 13, 17, 21, 24, 27, 30, 32, 34, 36, 39, 40, 43, 50, 51, 52, 56, 57, 59, 60, 61, 62, 65, 68, 72 ],
    maja: 76, // 73/79aw
    ada: 180,
  },
  // 20200630
  // {
  //   title: "2020-06-30 1",
  //   data: [ 1, 2, 4, 10, 11, 12, 13, 16, 17, 21, 43, 46, 49, 51, 52, 57, 59, 60, 61, 62, 65, 68, 72 ],
  //   maja: 82, // 83/81aw
  //   ada: 104,
  // },
  {
    title: "2020-06-30 2",
    data: [ 1, 2, 4, 7,  10, 11, 12, 13, 17, 21, 24, 26, 30, 31, 34, 36, 39, 40, 44, 45, 48, 51, 52, 55, 59, 60, 62, 68, 70, 72, 73 ],
    maja: 85, // 83/86a
    ada: 101,
  },
  // {
  //   title: "2020-06-30 3",
  //   data: [ 1, 2, 5, 7,  10, 11, 12, 16, 17, 21, 24, 26, 30, 33, 34, 36, 39, 40, 43, 46, 48, 51, 52, 55, 57, 59, 60, 61, 62, 65, 68, 72 ],
  //   maja: 78, // 77/79aw
  //   boogie: 147,
  // },
  // 20200702
  // {
  //   title: "2020-07-02 1",
  //   data: [ 1, 2, 4, 9, 10, 12, 13, 16, 17, 21, 24, 26, 30, 33, 34, 36, 39, 40, 43, 46, 47, 49, 51, 55, 57, 59, 60, 61, 68, 69, 70, 71 ],
  //   maja: 78, // 74/82aw
  //   boogie: 192,
  // },
  // {
  //   title: "2020-07-02 2",
  //   data: [ 1, 2, 5, 9, 10, 12, 13, 16, 17, 21, 24, 26, 30, 32, 35, 36, 39, 40, 43, 46, 49, 50, 51, 55, 56, 59, 61, 63, 64, 65, 66, 71 ],
  //   maja: 91, // 83/98aw
  //   boogie: 167,
  // },
  {
    title: "2020-07-02 3",
    data: [ 1, 2, 4, 9, 10, 12, 13, 16, 17, 21, 24, 26, 30, 32, 35, 36, 39, 40, 43, 46, 48, 50, 51, 55, 56, 59, 61, 63, 64, 65, 66, 71 ],
    maja: 90, // 87/92aw
    ada: 167,
  },
  {
    title: "2020-07-02 4",
    data: [ 1, 2, 4, 9, 10, 12, 13, 15, 17, 20, 24, 26, 30, 32, 35, 36, 39, 40, 43, 46, 48, 50, 51, 55, 56, 59, 61, 63, 64, 65, 66, 71 ],
    maja: 90, // 85/94aw
    ada: 97,
  },
  // // 20200703
  // {
  //   title: "2020-07-03 1",
  //   data: [ ],
  //   maja: 88,
  //   boogie: 78,
  // },
  // {
  //   title: "2020-07-03 2",
  //   data: [ ],
  //   maja: 74,
  //   boogie: 152,
  // },
  // {
  //   title: "2020-07-03 3",
  //   data: [ ],
  //   maja: 74,
  //   ada: 210,
  // },
  // {
  //   title: "2020-07-03 4",
  //   data: [ ],
  //   maja: 87,
  //   ada: 210,
  // },
  // // 202007
  // {
  //   title: "2020-07-03 4",
  //   data: [ ],
  //   maja: ,
  // },
  // {
  //   title: "2020-07-03 4",
  //   data: [ ],
  //   maja: ,
  // },
  // {
  //   title: "2020-07-03 4",
  //   data: [ ],
  //   maja: ,
  // },
  // {
  //   title: "2020-07-03 4",
  //   data: [ ],
  //   maja: ,
  // },
  // {
  //   title: "2020-07-03 4",
  //   data: [ ],
  //   maja: ,
  // },
];
